<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header breadcrumb />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="mx-auto px-2 py-2 sm:px-4 xl:px-6 flex flex-col space-y-8">
          <div id="vue-root" class="text-black">
            <div class="flex flex-wrap justify-between gap-2 lg:gap-4">
              <input
                type="text"
                class="form-control rounded-sm mb-3 border-gray-300 w-full lg:w-3/12 italic"
                v-model="filter"
                placeholder="Search Members"
                @keydown="$event.stopImmediatePropagation()"
              />
              <div class="flex justify-between gap-2 lg:gap-4">
                <button
                  class="text-white text-sm sm:text-base bg-red-600 hover:bg-green-500 font-bold px-3 rounded-sm inline-flex items-center h-10"
                  @click="goToAddMember()"
                  v-if="getPermission('Members', 'add') == 1"
                >
                  <font-awesome-icon
                    :icon="['fas', 'user-plus']"
                    size="lg"
                    class="mr-2"
                  />
                  New Member
                </button>
                <button
                  class="text-white text-sm sm:text-base bg-red-600 hover:bg-green-500 font-bold px-3 rounded-sm inline-flex items-center h-10"
                  @click.stop.prevent="chooseFile"
                  v-if="getPermission('MembersExcel', 'add') == 1"
                >
                  <font-awesome-icon
                    :icon="['fas', 'user-plus']"
                    size="lg"
                    class="mr-2"
                  />
                  Upload Excel
                </button>
                <input
                  id="memberExcel"
                  type="file"
                  ref="file"
                  hidden
                  accept=".xlsx, .xls"
                  multiple
                  @change="onChange"
                />
              </div>
              <div class="flex flex-1 justify-end text-black py-2 items-center">
                Showing
                <dropdown
                  arrow
                  placement="left"
                  class="text-black flex justify-center items-center hover:bg-gray-300 rounded-sm bg-gray-200 px-2 mx-2"
                >
                  <template v-slot:button>
                    <div>
                      {{ itemsPerPage != 0 ? itemsPerPage : "All" }}
                    </div>
                  </template>
                  <template v-slot:content>
                    <div class="flex flex-row text-base">
                      <ul class="item-menu">
                        <!-- <li @click="changeItemsPerPage(5)">5</li> -->
                        <li @click="changeItemsPerPage(20)">20</li>
                        <li @click="changeItemsPerPage(40)">40</li>
                        <li @click="changeItemsPerPage(0)">All</li>
                      </ul>
                    </div>
                  </template>
                </dropdown>
                entries
              </div>
            </div>
            <div class="flex justify-between">
              <button
                @click="scrollLeft()"
                v-if="showArrows"
                class="focus:outline-none hover:bg-gray-300"
              >
                <font-awesome-icon
                  :icon="['fas', 'angle-left']"
                  size="2x"
                  color="grey"
                  class="w-auto h-10 object-contain align-center mx-2 block lg:hidden"
                />
                <font-awesome-icon
                  :icon="['fas', 'angle-left']"
                  size="7x"
                  color="grey"
                  class="w-auto h-10 object-contain align-center mx-2 hidden lg:block"
                />
              </button>
              <button
                @click="scrollRight()"
                v-if="showArrows"
                class="focus:outline-none hover:bg-gray-300"
              >
                <font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  size="2x"
                  color="grey"
                  class="w-auto h-10 object-contain align-center mx-2 block lg:hidden"
                />
                <font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  size="7x"
                  color="grey"
                  class="w-auto h-10 object-contain align-center mx-2 hidden lg:block"
                />
              </button>
            </div>
            <div class="overflow-x-auto datatable w-full" ref="content">
              <datatable
                name="tableDisplay"
                ref="datatableRef"
                :columns="columns"
                :data="rows"
                :filter="filter"
                :per-page="itemsPerPage"
                class="text-black text-xs xl:text-sm 2xl:text-tiny mt-0"
              >
                <!-- eslint-disable-next-line -->
                <template slot-scope="{ row, columns }">
                  <tr>
                    <td>{{ getAttribute(row.Attributes, "custom:mmhId") }}</td>
                    <td>
                      {{ getAttribute(row.Attributes, "custom:preceptoryId") }}
                    </td>
                    <td>{{ getAttribute(row.Attributes, "custom:title") }}</td>
                    <td>{{ getAttribute(row.Attributes, "family_name") }}</td>
                    <td>{{ getAttribute(row.Attributes, "name") }}</td>
                    <td>{{ getAttribute(row.Attributes, "birthdate") }}</td>
                    <td>
                      {{ getAttribute(row.Attributes, "custom:joindate") }}
                    </td>
                    <td>{{ getAttribute(row.Attributes, "phone_number") }}</td>
                    <td>{{ getAttribute(row.Attributes, "email") }}</td>
                    <td>
                      {{ getAttribute(row.Attributes, "custom:occupation") }}
                    </td>
                    <td>{{ getAttribute(row.Attributes, "custom:role") }}</td>
                    <td>
                      {{
                        getDate(
                          getAttribute(row.Attributes, "custom:lastLogin")
                        )
                      }}
                    </td>
                    <td>
                      <div class="text-center text-red-600 font-bold flex">
                        <button
                          class="m-1"
                          @click="goToEditMember(row)"
                          v-if="getPermission('Members', 'update') == 1"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'user-edit']"
                            class="w-auto object-contain mx-1"
                          />
                        </button>
                        <button
                          class="m-1"
                          @click="deleteMemberConfirm(row)"
                          v-if="getPermission('Members', 'delete') == 1"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'trash-alt']"
                            class="w-auto object-contain mx-1"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </datatable>
            </div>
            <div class="flex justify-end">
              <div class="flex items-center mb-3">
                Page:
              </div>
              <datatable-pager
                ref="tablePager"
                table="tableDisplay"
                type="abbreviated"
              ></datatable-pager>
            </div>
          </div>
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter, Dropdown } from "../../components";
import api from "@/api/apiServices";
import XLSX from "xlsx";
import moment from "moment";

export default {
  name: "Member",
  components: {
    HomeFooter,
    HomeHeader,
    Dropdown,
  },
  async mounted() {
    if (this.getPermission("Members", "get") == 0) {
      this.$fire({
        title: "Access Error",
        text: "User does not have permissions to access the page",
        type: "error",
      });
      this.$router.push({ name: "Home" });
    } else {
      window.addEventListener("resize", this.onResize);
      this.getMember();
      this.$nextTick(function() {
        this.$refs.datatableRef.sortBy = this.$refs.datatableRef.normalizedColumns[0]; // replace the '2' with the index of the column of your choice.
        this.$refs.datatableRef.sortDir = "asc";
        setTimeout(() => this.onResize(), 500);
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  data() {
    return {
      utcOffset: this.$moment().utcOffset(),
      showArrows: true,
      filter: "",
      page: 1,
      itemsPerPage: 20,
      columns: [
        {
          label: "MMH-ID",
          headerClass: "font-semibold border-2 h-10",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter(
              (data) => data.Name == "custom:mmhId"
            );
            return `${value[0] ? value[0].Value.padStart(16, "0") : ""}`;
          },
          interpolate: true,
        },
        {
          label: "Preceptory",
          headerClass: "font-semibold border-2 h-10",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter(
              (data) => data.Name == "custom:preceptoryId"
            );
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "Title",
          headerClass: "font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter(
              (data) => data.Name == "custom:title"
            );
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "Surname",
          headerClass: "font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter((data) => data.Name == "family_name");
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "Given Name",
          headerClass: "font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter((data) => data.Name == "name");
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "DOB",
          headerClass: "font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter((data) => data.Name == "birthdate");
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "DOJ",
          headerClass: "font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter(
              (data) => data.Name == "custom:joindate"
            );
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "Mobile",
          headerClass: "font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter(
              (data) => data.Name == "phone_number"
            );
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "Email",
          headerClass: "font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter((data) => data.Name == "email");
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "Occupation",
          headerClass: "font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter(
              (data) => data.Name == "custom:occupation"
            );
            return `${value[0] ? value[0].Value : ""}`;
          },
          interpolate: true,
        },
        {
          label: "Role",
          headerClass: " font-semibold border-2 ",
          representedAs: ({ Attributes }) => {
            let obj = Attributes.filter((data) => data.Name == "custom:role");
            let value = JSON.parse(obj[0].Value);
            return `${value.roleName}`;
          },
          interpolate: true,
        },
        {
          label: "Last Login",
          headerClass: " font-semibold border-2",
          representedAs: ({ Attributes }) => {
            let value = Attributes.filter(
              (data) => data.Name == "custom:lastLogin"
            );
            if (value.length == 0) {
              return "N/A";
            }
            return `${value[0].Value}`;
          },
          interpolate: true,
        },
        {
          label: "Action",
          headerClass: "font-semibold border-2",
          interpolate: true,
        },
      ],
      rows: [],
      permission: this.$store.getters["security/currentPermission"],
      loader: null,
    };
  },
  methods: {
    showLoader() {
      this.loader = this.$loading.show({
        canCancel: false,
        onCancel: this.onCancel,
      });
    },
    hideLoader() {
      this.loader.hide();
    },
    getDate(dateString) {
      if (dateString == "N/A") {
        return "N/A";
      }
      var date = new Date(dateString);
      return date.toLocaleString("en-GB");
    },
    changeItemsPerPage(count) {
      this.$refs.datatableRef.page = 1;
      this.itemsPerPage = count;
    },
    sideScroll(element, direction, speed, distance, step) {
      var scrollAmount = 0;
      var slideTimer = setInterval(function() {
        if (direction == "left") {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
    scrollLeft() {
      let content = this.$refs.content;
      this.sideScroll(content, "left", 20, 100, 40);
    },
    scrollRight() {
      let content = this.$refs.content;
      this.sideScroll(content, "right", 20, 100, 40);
    },
    async getMember() {
      this.showLoader();
      api.getUser().then((res) => {
        if (res) {
          this.rows = res.data.Users;
          this.hideLoader();
        }
      });
    },
    onResize() {
      let content = this.$refs.content;
      if (content == undefined) {
        this.showArrows = true;
      } else {
        this.showArrows = content.clientWidth < content.scrollWidth;
      }
    },
    getAttribute(attributes, type) {
      try {
        let value = attributes.filter((data) => data.Name == type);
        if (type == "custom:role") {
          let temp = JSON.parse(value[0].Value);
          return temp.roleName;
        } else if (type == "custom:preceptoryId") {
          var arrayString = JSON.parse(value[0].Value).join(", ");
          return arrayString;
        } else if (type == "custom:joindate" || type == "birthdate") {
          var string = value[0].Value.split("-")
            .reverse()
            .join("/");
          return string;
        } else {
          return value[0].Value;
        }
      } catch (err) {
        return "N/A";
      }
    },
    getPermission(module, type) {
      for (let i = 0; i < this.permission.length; i++) {
        const element = this.permission[i];
        if (module == element.module) {
          return element[type];
        }
      }
    },
    goToEditMember(member) {
      this.$router.push({
        name: `EditMember`,
        params: { data: member },
      });
    },
    async deleteMemberConfirm(row) {
      let email = row.Attributes.filter((attr) => attr.Name === "email").pop()
        .Value;
      this.$confirm(`Are you sure want to delete this member "${email}" ?`)
        .then((res) => {
          if (res == true) {
            var user = this.$store.getters["security/currentUser"];
            if (user.username == row.Username) {
              this.$confirm(
                "Are you sure you want to delete your own membership?"
              )
                .then(() => {
                  api
                    .deleteUser(row)
                    .then(() => {
                      this.$store.commit("security/purgeAuth");
                      this.$fire({
                        title: "Member deleted",
                        type: "success",
                        timer: 3000,
                      });
                      this.$router.push({ name: "Home" });
                    })
                    .catch((error) => {
                      this.$fire({
                        title: "Failed to delete",
                        text: error.message,
                        type: "error",
                      });
                    });
                })
                .catch(() => {});
            } else {
              api
                .deleteUser(row)
                .then(() => {
                  this.$fire({
                    title: "Member deleted",
                    type: "success",
                    timer: 3000,
                  });
                  this.getMember();
                })
                .catch((error) => {
                  this.$fire({
                    title: "Failed to delete",
                    text: error.message,
                    type: "error",
                  });
                });
            }
          }
        })
        .catch(() => {});
    },
    goToAddMember() {
      this.$router.push({
        name: `AddMember`,
      });
    },
    async chooseFile() {
      document.getElementById("memberExcel").click();
    },
    onChange(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();

      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var wb = XLSX.read(data, {
          type: "array",
          cellDates: true,
          dateNF: "YYYY-MM-DD",
        });
        var ws = wb.Sheets[wb.SheetNames[0]];
        let result = XLSX.utils.sheet_to_json(ws);

        var users = [];

        api.getRolePermission().then((permission_res) => {
          result.forEach((element) => {
            let check = this.rows.filter((row) => {
              return (
                this.getAttribute(row.Attributes, "email") == element.Email
              );
            });
            if (check.length == 0) {
              let preceptory = element.Preceptory;
              let temp = preceptory.split(",");
              let id = [];
              temp.forEach((item) => {
                var element = item.trim();
                if (element == "SOEPP") {
                  id.push("277");
                } else if (element == "GCPP") {
                  id.push("290");
                } else if (element == "SSPP") {
                  id.push("657");
                } else if (element == "SPP") {
                  id.push("711");
                } else if (element == "SONPP") {
                  id.push("716");
                } else if (element == "SSEAPP") {
                  id.push("723");
                } else if (element == "INV") {
                  id.push("724");
                }
              });
              var permission = {};

              for (let index = 0; index < permission_res.data.length; index++) {
                var permission_data = permission_res.data[index];
                if (element.Role == permission_data.name) {
                  permission = {
                    roleId: permission_data.roleId,
                    roleName: permission_data.name,
                  };
                  break;
                }
              }

              var user = {
                name: element["Given Name"],
                surname: element.Surname,
                birthdate: element.DOB
                  ? moment(element.DOB)
                      .add(this.utcOffset, "m")
                      .format("YYYY-MM-DD")
                  : "",
                joindate: element["Joining Date"]
                  ? moment(element["Joining Date"])
                      .add(this.utcOffset, "m")
                      .format("YYYY-MM-DD")
                  : "",
                phone: element["Contact No"]
                  ? this.formatNumber(element["Contact No"])
                  : "+6511111111",
                email: element.Email.trim(),
                title: element.Prefix.trim(),
                mmhId: String(element.MMH).trim(),
                occupation: element.Profession ? element.Profession.trim() : "",
                role: permission,
                preceptoryId: id,
              };
              users.push(user);
            }
          });
          if (users.length > 0) {
            let params = {
              users: users,
            };

            api.registerUserWithList(params).then((res) => {
              if (res) {
                this.$fire({
                  title: "Members added",
                  type: "success",
                  timer: 3000,
                });
                this.getMember();
              } else {
                this.$fire({
                  title: "something happend, please try again.",
                  type: "error",
                  timer: 3000,
                });
                this.getMember();
              }
            });
          } else {
            this.$fire({
              title: "No members added",
              type: "success",
              timer: 3000,
            });
            this.getMember();
          }
          this.$refs.file.value = "";
        });
      }.bind(this);
      reader.readAsArrayBuffer(f);
    },
    getIcon(status) {
      if (status == "CONFIRMED") {
        return "check";
      } else return "times";
    },
    getIconColor(status) {
      if (status == "CONFIRMED") {
        return "green";
      } else return "red";
    },
    formatNumber(phone) {
      var ret = phone;
      if (typeof phone === "string" || phone instanceof String) {
        ret = phone.replace(/ /g, "");
        if (ret[0] == "+") {
          return ret;
        }
      }
      return "+" + ret;
    },
  },
};
</script>

<style src="../../styles/table.css"></style>
<style lang="css">
th {
  background-color: #3a4750;
  color: white;
  border: 1px solid;
  border-color: white;
}
.datatable::-webkit-scrollbar {
  -webkit-appearance: none;
}

.datatable::-webkit-scrollbar:horizontal {
  height: 12px;
}

.datatable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.datatable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}
</style>
<style scoped lang="postcss">
.item-menu {
  @apply py-1;
}
.item-menu > li {
  @apply p-2 text-base text-black hover:bg-gray-200 cursor-pointer transition-all duration-300 select-none;
}
.datatable::-webkit-scrollbar {
  -webkit-appearance: none;
}

.datatable::-webkit-scrollbar:horizontal {
  height: 12px;
}

.datatable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.datatable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}
</style>
