<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header breadcrumb />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="py-2">
          <div class="mx-auto px-4 sm:px-6 md:px-8 flex flex-col">
            <div id="vue-root" class="text-black text-base sm:text-lg">
              <form id="registerMember" @submit.prevent="checkForm">
                <div
                  class="w-full px-4 lg:px-6 my-4 grid grid-flow-row gap-2 lg:gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-base 2xl:text-xl"
                >
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Title</p>
                    <vue-simple-suggest
                      type="text"
                      placeholder="Title"
                      class="rounded-md mb-3 border-gray-300 mt-2"
                      v-model="title"
                      :list="titleSuggestions"
                      :filter-by-query="true"
                      required
                    >
                    </vue-simple-suggest>
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">MMH-ID</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="mmhid"
                      placeholder="MMH-ID"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Surname</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="surname"
                      placeholder="Surname"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Given Name</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="name"
                      placeholder="Given Name"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Email</p>
                    <input
                      type="email"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="email"
                      placeholder="Email"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Date Of Birth</p>
                    <input
                      type="date"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="birthDate"
                      placeholder="Date of birth"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Date of Joining</p>
                    <input
                      type="date"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="joinDate"
                      placeholder="Date of joining"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Mobile Phone</p>
                    <input
                      type="tel"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="phone"
                      placeholder="+65XXXXXXXX"
                      pattern="\+[0-9]+$"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Occupation</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="occupation"
                      placeholder="Occupation"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Role</p>
                    <select
                      class="form-select rounded-md mb-3 border-gray-300 mt-2"
                      v-model="selectedRole"
                      required
                    >
                      <option v-for="role in roleList" :key="role.roleId">
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-full px-4 lg:px-6 my-4 lg:my-8">
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Preceptory</p>
                    <multiselect
                      v-model="selectedPreceptories"
                      :options="preceptoryList"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Select preceptory"
                      label="name"
                      track-by="name"
                      :hideSelected="true"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="flex flex-row py-4 px-4 lg:px-6">
                  <button
                    type="submit"
                    class="text-white text-tiny sm:text-base bg-red-600 hover:bg-green-500 font-bold px-3 rounded-sm inline-flex items-center mr-2 h-10"
                  >
                    Add Member
                  </button>
                </div>
              </form>
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
import  preceptory  from "../../assets/json/preceptory.json";

import api from "@/api/apiServices";
import Multiselect from "vue-multiselect";
import VueSimpleSuggest from "vue-simple-suggest";

export default {
  name: "AddMember",
  components: {
    HomeFooter,
    HomeHeader,
    Multiselect,
    VueSimpleSuggest,
  },
  async mounted() {
    this.getRoleList();
  },
  data() {
    return {
      name: "",
      surname: "",
      birthDate: "",
      joinDate: "",
      phone: "",
      email: "",
      title: "",
      mmhid: "",
      selectedRole: "",
      occupation: "",
      selectedPreceptories: [],
      preceptoryId: [],
      preceptoryList: preceptory,
      roleList: [],
      autoCompleteStyle: {
        suggestions: "bg-gray-300",
        suggestItem: "list-group-item",
      },
      loader: null,
    };
  },
  methods: {
    showLoader() {
      this.loader = this.$loading.show({
        canCancel: false,
        onCancel: this.onCancel,
      });
    },
    hideLoader() {
      this.loader.hide();
    },
    titleSuggestions() {
      return ["Bro Kt", "Em Kt", "Rt Em Kt"];
    },
    addPreceptory() {
      if (this.preceptoryShow < this.preceptoryList.length) {
        this.preceptoryShow += 1;
      }
    },
    async getRoleList() {
      api.getRolePermission().then((res) => {
        this.roleList = res.data;
      });
    },
    async checkForm() {
      let params = {
        name: this.name.trim(),
        surname: this.surname.trim(),
        birthdate: this.birthDate,
        joindate: this.joinDate,
        phone: this.phone,
        email: this.email.trim(),
        title: this.title.trim(),
        mmhId: this.mmhid.trim(),
        occupation: this.occupation.trim(),
      };
      this.showLoader();
      for (let i = 0; i < this.roleList.length; i++) {
        const element = this.roleList[i];
        if (element.name == this.selectedRole) {
          var obj = {
            roleId: element.roleId,
            roleName: element.name,
          };
          params.role = obj;
          break;
        }
      }
      if (this.selectedPreceptories.length == 0) {
        this.hideLoader();
        this.$fire({
          title: "Add Failure",
          text: "Please select atleast one preceptory",
          type: "error",
        });
        return;
      }
      this.selectedPreceptories.forEach((item) =>
        this.preceptoryId.push(item.id)
      );
      params.preceptoryId = this.preceptoryId;
      api.registerUser(params).then((res) => {
        this.preceptoryId = [];
        if (res && res.status === 200) {
          this.hideLoader();
          this.$fire({
            title: "New member Added.",
            text: res.data.message,
            type: "success",
          }).then(() => {
            this.resetForm();
          });
        } else {
          this.hideLoader();
          this.$fire({
            title: "Fail to Add",
            text: res.data.Message,
            type: "error",
          });
        }
      });
    },
    async resetForm() {
      this.name = "";
      this.surname = "";
      this.birthDate = "";
      this.joinDate = "";
      this.phone = "";
      this.email = "";
      this.title = "";
      this.mmhid = "";
      this.selectedRole = "";
      this.occupation = "";
      this.selectedPreceptories = [];
      this.preceptoryId = [];
    },
  },
};
</script>

<style src="../../styles/vue-multiselect.css"></style>
<style src="../../styles/simple-suggest.css"></style>
