<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header breadcrumb />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="py-2">
          <div class="mx-auto px-4 sm:px-6 md:px-8 flex flex-col">
            <div id="vue-root" class="text-black text-base sm:text-lg">
              <form id="registerMember" @submit.prevent="checkForm">
                <div
                  class="w-full px-4 lg:px-6 my-4 grid grid-flow-row gap-4 lg:gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-base 2xl:text-xl"
                >
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Title</p>
                    <vue-simple-suggest
                      type="text"
                      placeholder="Title"
                      class="rounded-md mb-3 border-gray-300 mt-2"
                      v-model="title"
                      :list="titleSuggestions"
                      :filter-by-query="true"
                      required
                    >
                    </vue-simple-suggest>
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">MMH-ID</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="mmhid"
                      placeholder="MMH-ID"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Surname</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="surname"
                      placeholder="Surname"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Given Name</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="name"
                      placeholder="Given Name"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Email</p>
                    <input
                      type="email"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="email"
                      placeholder="Email"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Date Of Birth</p>
                    <input
                      type="date"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="birthDate"
                      placeholder="Date of birth"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Date of Joining</p>
                    <input
                      type="date"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="joinDate"
                      placeholder="Date of joining"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Mobile Phone</p>
                    <input
                      type="tel"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="phone"
                      placeholder="+65XXXXXXXX"
                      pattern="\+[0-9]+$"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Occupation</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="occupation"
                      placeholder="Occupation"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Role</p>
                    <select
                      class="form-select rounded-md mb-3 border-gray-300 mt-2"
                      v-model="selectedRole"
                      required
                    >
                      <option v-for="role in roleList" :key="role.roleId">
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-full px-4 lg:px-6 my-4 lg:my-8">
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Preceptory</p>
                    <multiselect
                      v-model="selectedPreceptories"
                      :options="preceptoryList"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Select preceptory"
                      label="name"
                      track-by="name"
                      :hideSelected="true"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="flex flex-row py-4 px-4 lg:px-6">
                  <button
                    type="submit"
                    class="text-white text-tiny sm:text-base bg-red-600 hover:bg-green-500 font-bold px-3 rounded-sm inline-flex items-center mr-2 h-10"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    @click="resetPassword()"
                    class="text-white text-tiny sm:text-base bg-red-600 hover:bg-green-500 font-bold px-3 rounded-sm inline-flex items-center mr-2 h-10"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
import  preceptory  from "../../assets/json/preceptory.json";

import api from "@/api/apiServices";
import Multiselect from "vue-multiselect";
import VueSimpleSuggest from "vue-simple-suggest";

export default {
  name: "EditMember",
  components: {
    HomeFooter,
    HomeHeader,
    Multiselect,
    VueSimpleSuggest,
  },
  async mounted() {
    if (this.$route.params.data) {
      this.getRoleList();
      this.getUserInformation(this.$route.params.data);
    } else {
      this.$router.push({ name: "Member" });
    }
  },
  data() {
    return {
      name: "",
      surname: "",
      birthDate: "",
      joinDate: "",
      phone: "",
      email: "",
      title: "",
      mmhid: "",
      preceptoryId: [],
      selectedRole: "",
      selectedPreceptories: [],
      occupation: "",
      roleList: [],
      preceptoryList: preceptory,
      loader: null,
    };
  },
  methods: {
    showLoader() {
      this.loader = this.$loading.show({
        canCancel: false,
        onCancel: this.onCancel,
      });
    },
    hideLoader() {
      this.loader.hide();
    },
    titleSuggestions() {
      return ["Bro Kt", "Em Kt", "Rt Em Kt"];
    },
    async getRoleList() {
      api.getRolePermission().then((res) => {
        this.roleList = res.data;
      });
    },
    async getUserInformation(data) {
      for (let i = 0; i < data.Attributes.length; i++) {
        const element = data.Attributes[i];
        if (element.Name == "name") {
          this.name = element.Value;
        } else if (element.Name == "custom:title") {
          this.title = element.Value;
        } else if (element.Name == "family_name") {
          this.surname = element.Value;
        } else if (element.Name == "email") {
          this.email = element.Value;
        } else if (element.Name == "birthdate") {
          this.formatDate(element.Value).then((res) => {
            this.birthDate = res;
          });
        } else if (element.Name == "phone_number") {
          this.phone = element.Value;
        } else if (element.Name == "custom:occupation") {
          this.occupation = element.Value;
        } else if (element.Name == "custom:mmhId") {
          this.mmhid = element.Value;
        } else if (element.Name == "custom:role") {
          this.selectedRole = JSON.parse(element.Value).roleName;
        } else if (element.Name == "custom:joindate") {
          this.formatDate(element.Value).then((res) => {
            this.joinDate = res;
          });
        } else if (element.Name == "custom:preceptoryId") {
          var array = JSON.parse(element.Value);
          try {
            array.forEach((id) => {
              this.selectedPreceptories.push(
                this.preceptoryList.filter((item) => item.id === id).pop()
              );
            });
          } catch {
            this.selectedPreceptories = [];
          }
        }
      }
    },
    async formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    async resetPassword() {
      Auth.forgotPassword(this.$route.params.data["Username"])
        // eslint-disable-next-line
        .then(() => {
          this.$fire({
            title: "Reset Password",
            text: `A verification code for reset password has been sent to your email.`,
            type: "success",
          }).then(() => {
            this.$router.push({
              name: "Member",
            });
          });
        })
        .catch((err) => {
          if (
            err.message == "User password cannot be reset in the current state."
          ) {
            api.resendPassword(this.email).then(
              this.$fire({
                title: "Reset Password",
                text: "Temporary password has been resent to your email.",
                type: "success",
              })
            );
          } else {
            this.$fire({
              title: "Reset Password",
              text: err.message,
              type: "error",
            });
          }
        });
    },
    async checkForm() {
      let params = {
        name: this.name.trim(),
        surname: this.surname.trim(),
        birthdate: this.birthDate,
        joindate: this.joinDate,
        phone: this.phone,
        email: this.email.trim(),
        title: this.title.trim(),
        mmhId: this.mmhid.trim(),
        preceptoryId: this.preceptoryId,
        occupation: this.occupation.trim(),
        username: this.$route.params.data.Username,
      };
      this.showLoader();
      for (let i = 0; i < this.roleList.length; i++) {
        const element = this.roleList[i];
        if (element.name == this.selectedRole) {
          var obj = {
            roleId: element.roleId,
            roleName: element.name,
          };
          params.role = obj;
          break;
        }
      }
      if (this.selectedPreceptories.length == 0) {
        this.hideLoader();
        this.$fire({
          title: "Add Failure",
          text: "Please select atleast one preceptory",
          type: "error",
        });
        return;
      }
      this.selectedPreceptories.forEach((item) =>
        this.preceptoryId.push(item.id)
      );
      params.preceptoryId = this.preceptoryId;
      api
        .updateUser(params)
        .then((res) => {
          this.hideLoader();
          this.preceptoryId = [];
          if (res.data) {
            var user = this.$store.getters["security/currentUser"];
            if (this.$route.params.data["Username"] == user.username) {
              this.$store
                .dispatch("security/GET_USER_ROLE", {
                  role: params.role.roleId,
                })
                .then((res) => {
                  this.$store.commit("security/setPermission", {
                    role: params.role,
                    permission: res.data,
                  });
                  this.$fire({
                    title: "Edit successful",
                    text: res.data.message,
                    type: "success",
                    timer: 3000,
                  });
                  this.$router.push({ name: "Member" });
                });
            } else {
              this.$fire({
                title: "Edit successful",
                text: res.data.message,
                type: "success",
                timer: 3000,
              });
              this.$router.push({ name: "Member" });
            }
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.$fire({
            title: "Failed to edit",
            text: error.message,
            type: "error",
          });
        });
    },
    async resetForm() {
      this.name = "";
      this.surname = "";
      this.birthDate = "";
      this.joinDate = "";
      this.phone = "";
      this.email = "";
      this.title = "";
      this.mmhid = "";
      this.selectedPreceptories = [];
      this.preceptoryId = [];
      this.selectedRole = "";
      this.occupation = "";
    },
  },
};
</script>
<style src="../../styles/vue-multiselect.css"></style>
<style src="../../styles/simple-suggest.css"></style>
